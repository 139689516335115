import {
  organizationsCollection,
  drillsCollection,
  skillsCollection,
  playTypesCollection,
  db,
} from '@/utils/firebase';
import { mapState } from 'vuex';
import { remoteConfig } from '@/utils/firebase';

export default {
  data: () => ({
    organization: {},
    organizations: [],
    skills: [],
    ages: [],
    types: [],
    drills: [],
    skill_focus: [],
    positions: [],
    tempSkills: [],
    levels: [],
    programs: [],
    players: [],
    playTypes: [],
    isOldSelection: false,
  }),
  computed: {
    ...mapState({
      status: (state) => state.user.status,
      currentUser: (state) => state.user.user,
      orgId: (state) => state.user.orgId,
      customerId: (state) => state.user.customerId,
    }),
    isOldContent() {
      const remoteConf = remoteConfig.getValue('deprecated_features');
      const remoteVal = remoteConf._value || '';
      return remoteVal !== this.orgId;
    },
  },
  mounted() {
    this.isOldSelection = !this.isOldContent ? true : false;
  },
  methods: {
    getVariables() {
      if (this.orgId) {
        organizationsCollection
          .doc(this.orgId)
          .get()
          .then((doc) => {
            if (doc.exists) {
              let data = doc.data().variables;
              if (data) {
                let skills = [];
                if (data.skills) {
                  skills = Object.keys(data.skills).map(function (key) {
                    let vobj = {
                      key: Number(key),
                      value: data.skills[key],
                    };
                    return vobj;
                  });
                }
                let ages = [];
                if (data.ages) {
                  ages = Object.keys(data.ages).map(function (key) {
                    let vobj = {
                      key: Number(key),
                      value: data.ages[key],
                    };
                    return vobj;
                  });
                }
                let types = [];
                if (data.types) {
                  types = Object.keys(data.types).map(function (key) {
                    let vobj = {
                      key: Number(key),
                      value: data.types[key],
                    };
                    return vobj;
                  });
                }
                let positions = [];
                if (data.positions) {
                  positions = Object.keys(data.positions).map(function (key) {
                    let vobj = {
                      key: Number(key),
                      value: data.positions[key],
                    };
                    return vobj;
                  });
                }
                let levels = [];
                if (data.levels) {
                  levels = Object.keys(data.levels).map(function (key) {
                    let vobj = {
                      key: Number(key),
                      value: data.levels[key],
                    };
                    return vobj;
                  });
                }
                let programs = [];
                if (data.programs) {
                  programs = Object.keys(data.programs).map(function (key) {
                    let vobj = {
                      key: Number(key),
                      value: data.programs[key],
                    };
                    return vobj;
                  });
                }
                let players = [];
                if (data.players) {
                  players = Object.keys(data.players).map(function (key) {
                    let player = {
                      key: Number(key),
                      value: data.players[key],
                    };
                    return player;
                  });
                }
                this.types = types;
                this.ages = ages;
                this.skills = skills;
                this.positions = positions;
                this.levels = levels;
                this.programs = programs;
                this.players = players;
              } else {
                this.types = [];
                this.skills = [];
                this.ages = [];
                this.positions = [];
                this.levels = [];
                this.programs = [];
                this.players = [];
              }
            }
          });

        this.getSkills();
      }
    },
    async getSkills() {
      try {
        let res = skillsCollection.where('org_id', '==', this.orgId);

        let resSnap = await res.orderBy('name', 'asc').get();
        if (resSnap.docs.length) {
          let postsArray = [];
          resSnap.forEach((doc) => {
            let post = doc.data();
            post.id = doc.id;
            if (this.customerId) {
              if (
                (post.customer_id && post.customer_id === this.customerId) ||
                post.customer_id === null
              )
                postsArray.push(post);
            } else {
              if (!post.customer_id) postsArray.push(post);
            }
          });
          this.skill_focus = postsArray;
          this.tempSkills = postsArray;
        }
      } catch (err) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred : ${err.message}`,
        });
      }
    },
    async getOrg(id) {
      try {
        let resSnap = await organizationsCollection.doc(id).get();
        this.organization = resSnap.data();
        this.organization.id = resSnap.id;

        if (this.organization.variableLabel) {
          this.labels = { ...this.labels, ...this.organization.variableLabel };
        }
      } catch (err) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred : ${err.message}`,
        });
      }
    },
    async getDrills() {
      try {
        let resSnap = await drillsCollection.orderBy('name', 'asc').get();
        if (resSnap.docs.length) {
          let postsArray = [];
          resSnap.forEach((doc) => {
            let post = {};
            post.id = doc.id;
            post.name = doc.data().name;
            post.ages = doc.data().ages;
            post.types = doc.data().types;
            post.skills = doc.data().skills;
            post.duration = null;
            postsArray.push(post);
          });
          this.drills = postsArray;
        }
      } catch (err) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred : ${err.message}`,
        });
      }
    },
    async getPlayTypes(type, games) {
      try {
        this.playTypes = [];
        this.showSubtype = false;
        this.form.play_type_option = { id: null, name: null };
        if (games && games.length > 0) {
          let types = await playTypesCollection
            .where('category', '==', type)
            .where('org_id', '==', this.orgId)
            .where('types', 'array-contains-any', games)
            .get();
          if (types.docs.length) {
            let typeData = [];
            types.forEach((doc) => {
              let data = doc.data();
              data.id = doc.id;
              typeData.push(data);
            });
            this.playTypes = typeData;
          }
        }
      } catch (err) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred : ${err.message}`,
        });
      }
    },
    async getOrganizations() {
      try {
        let resSnap = await organizationsCollection
          .orderBy('name', 'asc')
          .get();
        if (resSnap.docs.length) {
          let postsArray = [];
          resSnap.forEach((doc) => {
            let post = doc.data();
            post.id = doc.id;
            postsArray.push(post);
          });
          this.organizations = postsArray;
        }
      } catch (err) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred : ${err.message}`,
        });
      }
    },
    async updateOrg(orgId) {
      try {
        await db
          .collection('organizations')
          .doc(orgId)
          .update({ updated: new Date() });
      } catch (err) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred : ${err.message}`,
        });
      }
    },
    moveToTop(values, items, column) {
      let field = column ? column : 'key';
      const newList = [
        ...items.filter((item) => values && values.indexOf(item[field]) !== -1),
        ...items.filter((item) => values && values.indexOf(item[field]) === -1),
      ];
      return newList;
    },
  },
};
