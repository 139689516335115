<template>
  <v-container id="data-tables" fluid tag="section">
    <base-material-card
      color="primary"
      icon="mdi-clipboard-text-play"
      inline
      class="px-5 py-3"
    >
      <template #after-heading>
        <v-toolbar flat>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            outlined
            dense
            style="max-width: 200px"
            class="mr-5"
          />
          <v-btn
            rounded
            :disabled="selected.length == 0"
            color="black"
            class="mx-2"
            @click="duplicateItem()"
          >
            Duplicate
          </v-btn>
          <v-btn
            rounded
            color="primary"
            class="mx-2"
            @click="dialogCreateUpdate = true"
          >
            Create
          </v-btn>
        </v-toolbar>
      </template>
      <v-divider class="mt-3" />
      <v-data-table
        v-model="selected"
        class="aq-tbl"
        :headers="headers"
        :items="items"
        :search.sync="search"
        :loading="loadingPage"
        show-select
      >
        <template #[`item.category`]="{ item }">
          <div class="text-center">
            {{ item.category | upText }}
          </div>
        </template>
        <template #[`item.sub`]="{ item }">
          <div class="text-center">
            {{ item.sub_type ? 'Yes' : 'No' }}
          </div>
        </template>
        <template #[`item.updated`]="{ item }">
          <div class="text-center">
            <div v-if="item.updated">
              {{ item.updated | formatDate('MMM DD YYYY') }}
              <span v-if="item.update_user_name">
                by {{ item.update_user_name }}
              </span>
            </div>
            <div v-else>
              {{ item.created | formatDate('MMM DD YYYY') }}
              <span v-if="item.add_user_name">by {{ item.add_user_name }}</span>
            </div>
          </div>
        </template>
        <template #[`item.actions`]="{ item }">
          <div>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  fab
                  icon
                  x-small
                  v-bind="attrs"
                  v-on="on"
                  @click="duplicateItem(item)"
                >
                  <v-icon small>mdi-content-duplicate</v-icon>
                </v-btn>
              </template>
              <span>Duplicate Play</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  fab
                  icon
                  x-small
                  v-bind="attrs"
                  v-on="on"
                  @click="editItem(item)"
                >
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit Play</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </base-material-card>

    <!-- Create Update Dialog -->
    <v-dialog v-model="dialogCreateUpdate" max-width="500px">
      <v-card>
        <v-card-title class="text-left">
          <span class="text-headline">{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-select
              v-model="form.category"
              :items="categories"
              :rules="[(v) => !!v || 'Category is required']"
              label="Play Type Category"
              outlined
              dense
              required
            ></v-select>
            <v-text-field
              v-model="form.name"
              label="Name"
              :rules="[(v) => !!v || 'Name is required']"
              outlined
              dense
              required
            />
            <v-text-field
              v-model="form.field"
              label="Field Name"
              :rules="[(v) => !!v || 'Field Name is required']"
              outlined
              dense
              required
            />
            <v-select
              v-model="form.types"
              :items="types"
              item-value="key"
              item-text="value"
              :rules="[(v) => !!v || 'Game types is required']"
              label="Game types"
              small-chips
              multiple
              outlined
              dense
              required
            >
              <template #selection="{ attrs, item, parent, selected }">
                <v-chip v-bind="attrs" :input-value="selected" label small>
                  <span class="pr-2">{{ item.value }}</span>
                  <v-icon small @click="parent.selectItem(item)">
                    mdi-close
                  </v-icon>
                </v-chip>
              </template>
            </v-select>
            <v-row v-if="hasSub">
              <v-col
                v-for="(item, key) in subTypes"
                :key="key"
                cols="12"
                sm="6"
                md="12"
              >
                <v-text-field
                  v-model="item.title"
                  label="Sub Type"
                  :rules="[(v) => !!v || 'Sub Type is required']"
                  outlined
                  dense
                  required
                  append-icon="mdi-close"
                  @click:append="removeSub(key)"
                />
              </v-col>
              <v-col cols="12" sm="6" md="12">
                <v-select
                  v-model="form.content"
                  :items="subDataType"
                  item-value="key"
                  item-text="value"
                  :rules="[(v) => !!v || 'Content Data is required']"
                  label="Is Sub Content data fixed?"
                  outlined
                  dense
                  required
                ></v-select>
              </v-col>
            </v-row>
            <div class="text-center">
              <v-btn outlined rounded text center @click="addSub">
                Add Sub Type
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn :loading="loading" color="info" rounded @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn :loading="loading" color="primary" rounded @click="save">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-left mb-3">
          Are you sure you want to delete this play type?
        </v-card-title>
        <v-divider />
        <v-card-text>
          This play type is currently being used in
          <b>{{ plays.length }} play(s)</b>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="info" rounded text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="error" rounded @click="deleteSubmit">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Duplicate Dialog -->
    <v-dialog v-model="dialogDuplicate" width="500">
      <v-card>
        <v-card-title class="text-left mb-3">Duplicate</v-card-title>
        <v-divider />
        <v-card-text>Are you sure you want to duplicate?</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="info"
            :loading="loading"
            text
            rounded
            @click="dialogDuplicate = false"
          >
            No
          </v-btn>
          <v-btn
            v-if="isSelectedDuplicate"
            color="error"
            rounded
            :loading="loading"
            @click="duplicateSelected"
          >
            Yes
          </v-btn>
          <v-btn
            v-else
            color="error"
            rounded
            :loading="loading"
            @click="duplicateSubmit"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCopy" width="500">
      <v-card>
        <v-card-title class="text-left mb-3">Copy</v-card-title>
        <v-divider />
        <v-card-text>Are you sure you want to copy?</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="info"
            :loading="loading"
            text
            rounded
            @click="dialogCopy = false"
          >
            No
          </v-btn>
          <v-btn color="error" rounded :loading="loading" @click="copySubmit">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import generic from '@/mixins/generic.js';
import {
  db,
  skillsCollection,
  playTypesCollection,
  usersCollection,
  playsCollection,
} from '@/utils/firebase';

export default {
  name: 'PlayType',
  mixins: [generic],
  component: {},
  data: () => ({
    title: 'Play Types',
    dialogTitle: 'Create Play Type',
    search: null,
    loading: false,
    loadingPage: false,
    dialogCreateUpdate: false,
    dialogDuplicate: false,
    dialogCopy: false,
    dialog: false,
    selectedItem: null,
    selected: [],
    isSelectedDuplicate: false,
    hasSub: false,
    valid: true,
    items: [],
    categories: ['offence', 'defence', 'formation', 'special'],
    users: {},
    form: {
      name: null,
      content: null,
      created: new Date(),
      add_user_id: null,
      category: null,
      field: null,
      types: null,
    },
    subTypes: [
      {
        title: null,
      },
    ],
    plays: [],
    subDataType: [
      { key: 'yes', value: 'Yes' },
      { key: 'no', value: 'No' },
    ],
  }),

  computed: {
    ...mapState({
      user: (state) => state.user.user,
      orgId: (state) => state.user.orgId,
      orgName: (state) => state.user.orgName,
      customerId: (state) => state.user.customerId,
      customerName: (state) => state.user.customerName,
    }),
    headers() {
      let header = [
        { text: 'Name', value: 'name' },
        { text: 'Play Category', value: 'category', width: '300px' },
        { text: 'Has Sub Type', value: 'sub' },
        { text: 'Updated', value: 'updated', width: '300px' },
        { text: 'Actions', value: 'actions', sortable: false, width: '120px' },
      ];

      return header;
    },
  },
  watch: {
    orgId: function (val) {
      this.getUsers();
      this.getVariables(val);
    },
  },
  created() {
    this.getUsers();
  },
  methods: {
    async getUsers() {
      try {
        let resSnap = usersCollection;
        if (this.orgId != null) {
          resSnap = resSnap.where('org_id', '==', this.orgId);
        }
        resSnap = resSnap.orderBy('name', 'desc');
        let querySnapshot = await resSnap.get();
        if (querySnapshot.docs.length > 0) {
          querySnapshot.forEach((doc) => {
            let post = doc.data();
            let user = {};
            user[doc.id] = post.name;
            this.users = Object.assign(this.users, user);
          });
        }
        this.initialize();
      } catch (err) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred : ${err.message}`,
        });
      }
    },

    initialize() {
      if (this.customerId) this.$router.push('/drills');
      this.loadingPage = true;
      this.getData();
      this.getVariables();
    },

    async getData() {
      try {
        this.items = [];
        await playTypesCollection
          .where('org_id', '==', this.orgId)
          .orderBy('created', 'desc')
          .onSnapshot((querySnapshot) => {
            let offence = [];
            querySnapshot.forEach((doc) => {
              let data = doc.data();
              data.id = doc.id;
              data.update_user_name = data.update_user_id
                ? this.users[data.update_user_id]
                : '';
              data.add_user_name = data.add_user_id
                ? this.users[data.add_user_id]
                : '';
              offence.push(data);
            });
            this.items = offence;
          });
        this.loadingPage = false;
      } catch (error) {
        this.loadingPage = false;
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred : ${error.message}`,
        });
      }
    },

    async save() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          let newPlayType = {
            name: this.form.name,
            field: this.form.field,
            types: this.form.types,
            category: this.form.category,
            add_user_id: this.user.uid,
            org_id: this.orgId,
            org_name: this.orgName,
            changed: false,
            created: this.form.created,
            sub_type: null,
          };

          const type = this.filterPlayTypes();
          if (type.length > 0) {
            newPlayType.sub_type = type;
            newPlayType.fixed = this.form.content;
          }

          if (this.form.id) {
            newPlayType.changed = true;
            newPlayType.add_user_id = this.form.add_user_id;
            newPlayType.update_user_id = this.user.uid;
            newPlayType.updated = new Date();
            await playTypesCollection.doc(this.form.id).set(newPlayType);
          } else {
            await playTypesCollection.doc().set(newPlayType);
          }
          this.$store.dispatch('notification/add', {
            type: 'success',
            message: 'Play Type successfully saved',
          });

          this.loading = false;
          this.dialogCreateUpdate = false;
        } catch (error) {
          this.loading = false;
          this.$store.dispatch('notification/add', {
            type: 'error',
            message: `An error occurred : ${error.message}`,
          });
        }

        this.clearForm();
      } else {
        this.dialogCreateUpdate = false;
      }
    },
    editItem(item) {
      if (!item || !item.id) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `Wrong item!`,
        });
        return;
      }
      if (this.customerId && !item.customer_id) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `Access denied : You can not edit the play type.`,
        });
        return;
      }
      if (
        this.customerId &&
        item.customer_id &&
        this.customerId !== item.customer_id
      ) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `Access denied : You can not edit the play type.`,
        });
        return;
      }
      this.hasSub = false;
      this.form.content = null;
      this.subTypes = [{ title: null }];
      let ref = playTypesCollection.doc(item.id);
      ref
        .get()
        .then((doc) => {
          let content = null;
          if (doc.exists) {
            content = doc.data();
            this.form.name = content.name;
            this.form.field = content.field;
            this.form.types = content.types;
            this.form.category = content.category;
            this.form.created = content.created;
            this.form.add_user_id = content.add_user_id;
            if (content.sub_type && content.sub_type.length > 0) {
              this.subTypes = [];
              content.sub_type.forEach((item) => {
                this.subTypes.push({
                  title: item.title,
                });
              });
              this.form.content = content.fixed;
              this.hasSub = true;
            }
            this.form.id = doc.id;
            this.dialogTitle = 'Edit Play Type';
            this.dialogCreateUpdate = true;
          } else {
            this.$store.dispatch('notification/add', {
              type: 'error',
              message: 'The document does not exist',
            });
          }
        })
        .catch((err) => {
          this.$store.dispatch('notification/add', {
            type: 'error',
            message: `An error occurred : ${error.message}`,
          });
        });
    },

    async deleteItem(item) {
      try {
        if (!item || !item.id) {
          this.$store.dispatch('notification/add', {
            type: 'error',
            message: `Wrong item!`,
          });
          return;
        }
        if (this.customerId && !item.customer_id) {
          this.$store.dispatch('notification/add', {
            type: 'error',
            message: `Access denied : You can not delete the play type.`,
          });
          return;
        }
        if (
          this.customerId &&
          item.customer_id &&
          this.customerId !== item.customer_id
        ) {
          this.$store.dispatch('notification/add', {
            type: 'error',
            message: `Access denied : You can not delete the play type.`,
          });
          return;
        }
        let ref = await playsCollection
          .where('org_id', '==', this.orgId)
          .where('play_type_option_id', '==', item.id)
          .get();
        let types = [];

        if (!ref.empty) {
          ref.forEach((doc) => {
            let post = {};
            post = doc.data();
            post.id = doc.id;
            post.customer_active = true;

            types = [...types, post];
          });
        }
        this.plays = types;

        this.selectedItem = item;
        this.dialog = true;
      } catch (err) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred : ${err.message}`,
        });
        this.dialog = true;
      }
    },
    async deleteSubmit(item) {
      try {
        if (this.plays.length > 0) {
          let batch = db.batch();
        } else {
          await skillsCollection.doc(this.selectedItem.id).delete();
        }
        this.$store.dispatch('notification/add', {
          type: 'success',
          message: `${this.selectedItem.name} has been deleted`,
        });
        this.dialog = false;
        this.selectedItem = null;
      } catch (err) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred : ${err.message}`,
        });
        this.dialog = false;
      }
    },
    duplicateItem(item) {
      if (item) {
        this.dialogDuplicate = true;
        this.selectedItem = item;
        this.isSelectedDuplicate = false;
      } else {
        this.dialogDuplicate = true;
        this.isSelectedDuplicate = true;
      }
    },
    async duplicateSubmit() {
      try {
        this.loading = true;
        delete this.selectedItem.id;
        delete this.selectedItem.update_user_id;
        delete this.selectedItem.updated;
        delete this.selectedItem.customer_active;
        delete this.selectedItem.add_user_name;
        delete this.selectedItem.removeItem;
        delete this.selectedItem.owner;

        this.selectedItem.created = new Date();
        this.selectedItem.name = this.selectedItem.name + ' - Duplicated';
        this.selectedItem.add_user_id = this.user.uid;
        await playTypesCollection.doc().set(this.selectedItem);

        this.dialogDuplicate = false;
        this.$store.dispatch('notification/add', {
          type: 'success',
          message: `${this.selectedItem.name} has been duplicated`,
        });
        this.selectedItem = null;
        this.loading = false;
      } catch (err) {
        this.dialogDuplicate = false;
        this.loading = false;
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred : ${err.message}`,
        });
      }
    },
    async duplicateSelected() {
      try {
        this.loading = true;
        await Promise.all(
          this.selected.map(async (row) => {
            delete row.id;
            delete row.update_user_id;
            delete row.updated;
            delete row.customer_active;
            delete row.add_user_name;
            delete row.removeItem;
            delete row.owner;

            row.created = new Date();
            row.name = row.name + ' - Duplicated';
            row.add_user_id = this.user.uid;
            await playTypesCollection.doc().set(row);
          }),
        );
        this.dialogDuplicate = false;
        this.selected = [];
        this.loading = false;
        this.$store.dispatch('notification/add', {
          type: 'success',
          message: `Selected items have been duplicated`,
        });
      } catch (err) {
        this.dialogDuplicate = false;
        this.loading = false;
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred : ${err.message}`,
        });
      }
    },
    copyItem(item) {
      this.dialogCopy = true;
      this.selectedItem = item;
    },
    async copySubmit() {
      try {
        this.loading = true;
        delete this.selectedItem.id;
        delete this.selectedItem.update_user_id;
        delete this.selectedItem.updated;
        delete this.selectedItem.customer_active;
        delete this.selectedItem.update_user_name;
        delete this.selectedItem.add_user_name;
        delete this.selectedItem.removeItem;
        delete this.selectedItem.owner;

        this.selectedItem.created = new Date();
        this.selectedItem.name = this.selectedItem.name + ' - Copied';
        this.selectedItem.changed = false;
        this.selectedItem.add_user_id = this.user.uid;
        this.selectedItem.customer_id = this.customerId || null;
        this.selectedItem.customer_name = this.$customerName || null;
        await skillsCollection.doc().set(this.selectedItem);

        this.dialogCopy = false;
        this.$store.dispatch('notification/add', {
          message: `${this.selectedItem.name} has been copied`,
          type: 'success',
        });
        this.selectedItem = null;
        this.loading = false;
      } catch (err) {
        this.dialogCopy = false;
        this.loading = false;
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred : ${err.message}`,
        });
      }
    },

    filterPlayTypes() {
      let types = [];
      const id = this.orgId;
      const name = this.orgName;
      const customer = this.customerId;
      this.subTypes.map(function (item) {
        let type = {};
        if (item.title !== null) {
          type.title = item.title;
          type.org_id = id;
          type.org_name = name;

          if (customer) {
            type.customer_id = customer;
          }
          types.push(type);
        }
      });

      return types;
    },

    addSub() {
      if (!this.hasSub) {
        this.hasSub = true;
      } else {
        this.subTypes.push({
          title: null,
        });
      }
    },

    removeSub(index) {
      this.subTypes.splice(index, 1);

      if (index === 0) {
        this.subTypes.push({
          title: null,
        });
        this.hasSub = false;
      }
    },

    closeDialog() {
      this.clearForm();
      this.dialogCreateUpdate = false;
    },

    clearForm() {
      this.dialogTitle = 'Create Play Type';
      this.form.name = null;
      this.form.content = null;
      this.form.created = new Date();
      this.form.add_user_id = null;
      this.form.category = null;
      this.subTypes = [
        {
          title: null,
        },
      ];
      this.hasSub = false;
    },
  },
};
</script>
